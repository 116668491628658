import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"

import BackgroundImage from "gatsby-background-image"
import { motion } from "framer-motion"

const BackgroundSection = ({ className, showHeader, imageData, children }) => {
  const variants = {
    hidden: {
      opacity: 0.0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#999`}
    >
      {children}
    </BackgroundImage>
  )
}

// const StyledBackgroundSection = ({ className, children }) => tw(
//   BackgroundSection(className, children)
// )`
// relative border-white bg-center bg-cover h-screen min-h-144 md:border-12
// `

export default BackgroundSection
