import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import { FormattedMessage } from "react-intl"
import tw, { css, styled } from "twin.macro"
import Flag from "react-world-flags"

const SelectLanguage = props => {
  const links = props.langs.map(lang => (
    <Link
      to={lang.link}
      key={lang.langKey}
      style={{
        color: "white",
      }}
    >
      {lang.langKey === "de" ? (
        <Flag code={lang.langKey} height="16" width="28" alt="Deutsch" />
      ) : (
        <Flag code={lang.langKey} height="12" width="22" alt="Dansk" />
      )}
      &nbsp;
    </Link>
  ))

  return <div css={tw`flex pt-5 justify-between w-16`}>{links}</div>
}

SelectLanguage.propTypes = {
  langs: PropTypes.array,
}

export default SelectLanguage
